import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Footer from '../components/Footer'
import SEO from "../components/SEO"
import {
  Container,
  Box,
} from '@chakra-ui/react'
import { H1 } from '../theme/Headings'
import { spacing, colors, breakpoints } from '../theme/themeAlopex'
// import BackgroundImage from 'gatsby-background-image'
import ColorContext from '../context/ColorContext'

const VideoPage = ({ data }) => {
  const { color } = useContext(ColorContext)
  
  // Clear transform matrix from transition wrapper interfering with nested fixed header
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const tlWrapper = document.querySelector('.tl-wrapper-status--entered')
      if (tlWrapper !== null) {
        tlWrapper.style.transform = null
      }
    }
  })

  return (
    <Layout>
      <SEO title='Video - Alopex' />
      <Box backgroundColor={color.alpha}>
        <Container maxWidth={breakpoints.xl} pt={spacing.header} pb={spacing.section} color={colors.brand.white}>
          <H1 variant="pageHeader" color={colors.brand.white} mb={spacing.groups}>{data?.contentJson?.title}</H1>
          <div dangerouslySetInnerHTML={{__html: data?.contentJson?.videos}} />
        </Container>
      </Box>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query aloVideo {
    site {
      siteMetadata {
        title
      }
    }
    contentJson(slug: {eq: "video"}) {
      title
      slug
      videos
    }
  }
`

export default VideoPage
